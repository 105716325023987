










































import { Component, Vue } from "vue-property-decorator";

@Component
export default class UserRoles extends Vue {
  public roles = [
    {
      name: "User",
      description: "Default role with minimal viewing permissions",
      items: [true, false, true, false, false, false, false, false]
    },
    {
      name: "Manager",
      description: "Talent Managers with account edit access",
      items: [true, false, true, true, false, false, true, false]
    },
    {
      name: "Super",
      description: "Talent Managers with elevated access",
      items: [true, false, true, true, true, false, true, true]
    },
    {
      name: "Alpha",
      description: "Senior staff and developers",
      items: [true, true, true, true, true, true, true, true]
    }
  ];

  public roleItems = [
    "View generic overview widgets",
    "View revenue charts",
    "View all data in Manage and Mastersheet sections",
    "Add/edit Creators",
    "Add/edit Manager Groups",
    "Add/edit Managers",
    "Add/edit Accounts",
    "Access to Export section"
  ];
}
